import React, { useState } from 'react'
import SALink from 'components/SALink'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

type Props = {
  links: Array<Record<string, any>>
  image: Record<string, any>
  isVisible: boolean
}

const SubNavLinks = ({
  links,
  image,
  isVisible,
}: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const [loadImage, handleLoadImage] = useState(false)

  if (isVisible && !loadImage) {
    handleLoadImage('true')
  }

  // Do we have SUB links to create a SUB menu
  if (Array.isArray(links) && image) {
    const { title: imageTitle, url: imageUrl, linkTo: imageLink } = image
    return (
      <div
        data-section='content'
        {...(isVisible
          ? {
              'data-visible': true,
            }
          : {})}
      >
        <div data-type='image-wrapper'>
          <SALink
            data-gtm-event='nav header'
            data-type='sub-menu-image'
            href={imageLink}
            alt={imageTitle}
          >
            <img
              {...(loadImage
                ? {
                    src: imageUrl,
                  }
                : {})}
              data-type='category-img'
              width='250'
              height='250'
            />
            <SATypography
              data-type='image-title'
              data-style={imageTitle}
              variant={SATypographyVariant.LARGE}
            >
              {imageTitle}
            </SATypography>
          </SALink>
          {imageTitle === 'Saatchi Art for Business' ? (
            <SATypography
              data-type='image-title'
              data-style='image-credit'
              variant={SATypographyVariant.LARGE}
            >
              Photo by Chase Daniel
            </SATypography>
          ) : (
            ''
          )}
        </div>
        <span data-type='line' />

        {links.map((subLinks: Record<string, any>, key: number) => {
          const { title: subTitle, links: subLinkItem } = subLinks
          return (
            <ul data-section='sub-nav-links' key={`${subTitle}-${key}`}>
              {subTitle && (
                <SATypography uppercase data-type='sub-title' variant={SATypographyVariant.H6}>
                  {subTitle}
                </SATypography>
              )}
              {subLinkItem.map((subLinkItemData, index) => {
                const { title: subLinkTitle, url: subLinkUrl } = subLinkItemData
                return (
                  <li key={`submenu-${index}`}>
                    <SALink data-gtm-event='nav header' data-type='sub-link' href={subLinkUrl}>
                      <SATypography variant={SATypographyVariant.MEDIUM}>
                        {subLinkTitle}
                      </SATypography>
                    </SALink>
                  </li>
                )
              })}
            </ul>
          )
        })}
      </div>
    )
  }

  return null
}

export default SubNavLinks
