import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import Visa from 'svg/visa.svg'
import Master from 'svg/master.svg'
import Discover from 'svg/discover.svg'
import AmericanExpress from 'svg/americanExpress.svg'
import JCB from 'svg/jcb.svg'
import BlueCard from 'svg/blueCard.svg'
import PayPal from 'svg/payPal.svg'
import SEPA from 'svg/sepa.svg'
import Affirm from 'svg/affirm.svg'
import Facebook from 'svg/facebook.svg'
import TikTok from 'svg/tiktok.svg'
import Twitter from 'svg/twitter.svg'
import Instagram from 'svg/instagram.svg'
import PinterestP from 'svg/pinterest-p.svg'
import Youtube from 'svg/youtube-play.svg'
import BackToTop from 'svg/back-to-top-icon.svg'
export const FooterNav = styled.footer`
  &[data-section='footer'] {
    position: relative;
    content-visibility: auto;
    contain-intrinsic-size: 1px 250px;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 600px;
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      min-height: 940px;
    }
  }

  section {
    display: flex;
    justify-content: space-between;
    padding: 0px 8% 15px;
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 10px 50px;
      margin-bottom: 50px;
    }
  }

  [data-type='line'] {
    border-top: 1px solid #e9e9e9;
    margin-top: 30px;
  }

  [data-type='icon-text-container'] {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  [data-type='back-to-top-button'] {
    width: 150px;
    height: 28px;
    border: solid 1px #e9e9e9;
    background-color: #ffffff;
    font-size: 10px;
    text-align: center;
    color: #333333;
    margin: auto;
    margin-top: -15px;
    cursor: pointer;
    &:hover {
      background-color: #e9e9e9;
    }
  }

  [data-section='nav-links'] {
    margin-top: 100px;
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      margin-top: 25px;
    }
    nav {
      width: 25%;
      h3 {
        margin: 0;
      }
      label svg {
        display: none;
      }
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        width: 100%;
        flex-direction: column;
        padding: 15px 0 0;
        border-bottom: 1px solid #e9e9e9;
        label {
          position: relative;
          display: flex;
          width: 100%;
          svg {
            display: block;
            position: absolute;
            right: 0;
            width: 12px;
            height: 12px;
            transform: rotate(90deg);
          }
        }
      }
    }
    [data-type='copyright'] {
      p {
        font-size: 10px;
        color: #666666;
        margin: 5px 0 0;
      }
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        display: none;
      }
    }
    input[type='checkbox'] {
      display: none;
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        ~ ul {
          padding-top: 10px;
          overflow: hidden;
          max-height: 0;
          transition: max-height 0.5s ease;
          will-change: max-height;
          contain: paint;
        }
        &:checked ~ ul {
          max-height: 300px;
          padding-bottom: 15px;
        }
        &:checked ~ label svg {
          transform: rotate(270deg);
        }
      }
    }
  }

  [data-section='icon-links'] {
    display: flex;
    justify-content: center;
    align-items: center;
    [data-type='social-icon'] {
      width: 33px;
      height: 33px;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      + a {
        margin-left: 7px;
      }
    }
    img {
      width: 75px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }

    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      order: -1;
    }
  }

  [data-section='email-signup'] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 225px;
    border-bottom: 1px solid #e9e9e9;
    h2 {
      font-weight: normal;
      margin: 0px;
      text-transform: uppercase;
      font-size: 15px;
      line-height: 24px;
    }
    p {
      margin: 0;
      &[data-type='form-message'] {
        &[data-status='error'] {
          position: absolute;
          bottom: -23px;
          left: 0;
          color: #dc3a0f;
          font-size: 12px;
        }
      }
      &[data-status='success'] {
        font-size: 21px;
        @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
          margin-top: 15px;
          font-size: 18px;
        }
      }
    }
    form {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      max-width: 650px;
      input {
        color: rgb(51, 51, 51);
        border-radius: 0px;
        border-top: none;
        border-right: none;
        border-left: none;
        border-image: initial;
        border-bottom: 1px solid rgb(233, 233, 233);
        font-size: 11px;
        padding: 15px;
        max-width: 500px;
        min-width: 200px;
        width: 100%;
        text-align: center;
        margin-right: 20px;
        ::placeholder {
          color: #999999;
        }
        :focus {
          outline: none;
          ::placeholder {
            color: #333333;
          }
        }
        &[data-status='error'] {
          border: 1px solid #dc3a0f;
        }
        &[data-status='success'] {
          display: none;
        }
      }
      button {
        text-align: center;
        text-decoration: none;
        font-size: 11px;
        padding: 10px;
        width: 185px;
        min-width: 185px;
        height: 40px;
        cursor: pointer;
        background-color: #333333;
        color: #ffffff;
        text-transform: uppercase;
        border: 1px solid transparent;
        box-shadow: rgb(240 240 240) 5px 5px 0px;
        transition: background-color 0.3s ease 0s, border-color 0.3s ease 0s;
        will-change: background-color;
        &:hover {
          background-color: #000000;
        }
        &:focus {
          outline: none;
        }
        &[data-status='success'] {
          display: none;
        }
      }
    }
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      height: auto;
      padding: 50px;
      h2,
      p {
        text-align: center;
      }
      form {
        width: 100%;
        flex-direction: column;
        input {
          width: 100%;
          max-width: 350px;
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
  h3,
  label {
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 7px;
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      font-size: 16px;
    }
  }
  a {
    font-size: 10px;
    color: #666666;
    transition: color 0.2s ease 0s;
    display: block;
    margin: 5px 0;
    text-decoration: none;
    letter-spacing: 0.9px;
    transition: 0.2s ease color;
    &:hover {
      color: #000000;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
      font-size: 16px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      color: #999999;
      font-size: 12px;
      line-height: 21px;
      margin: 0;
      &:hover {
        color: #333333;
      }
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a[data-type='social'] {
    font-size: 13px;
    color: #333333;
    height: 33px;
    width: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    + a {
      margin: 0 0 0 6px;
    }
    :hover {
      color: #000000;
    }
  }

  [data-section='icons'] {
    display: flex;
    align-items: center;
    span[data-type='spacer'] {
      display: flex;
      flex: 1 auto;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      margin-bottom: 0;
      a[data-type='sa-logo'] img {
        width: 150px;
        height: 50px;
      }
    }

    [data-type='copyright'] {
      display: none;
      p {
        text-transform: uppercase;
        margin-bottom: 7px;
        color: #666666;
        font-size: 11px;
        text-align: center;
        width: 100%;
        letter-spacing: 0.1em;
        margin: 2px 0;
      }
      [data-type='payment-icons'] {
        margin: 5px 0;
      }
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 5px 0;
      }
    }
  }
`
export const VisaIcon = styled(Visa)`
  height: 25px;
  width: 25px;
  margin: 1px;
`
export const MasterIcon = styled(Master)`
  width: 25px;
  height: 25px;
  margin: 1px;
`
export const DiscoverIcon = styled(Discover)`
  width: 25px;
  height: 25px;
  margin: 1px;
`
export const AmericanExpressIcon = styled(AmericanExpress)`
  width: 25px;
  height: 25px;
  margin: 1px;
`
export const JCBIcon = styled(JCB)`
  width: 25px;
  height: 25px;
  margin: 1px;
`
export const BlueCardIcon = styled(BlueCard)`
  width: 25px;
  height: 25px;
  margin: 1px;
`
export const PayPalIcon = styled(PayPal)`
  width: 25px;
  height: 25px;
  margin: 1px;
`
export const SEPAIcon = styled(SEPA)`
  width: 25px;
  height: 25px;
  margin: 1px;
`
export const AffirmIcon = styled(Affirm)`
  width: 25px;
  height: 25px;
  margin: 1px;
  svg {
    title: hidden;
  }
`
export const FacebookIcon = styled(Facebook)`
  height: 12px;
  width: 14px;
  fill: #666;
  &:hover {
    fill: #000;
  }
`
export const TikTokIcon = styled(TikTok)`
  height: 14px;
  width: 14px;
  fill: #666;
  &:hover {
    fill: #000;
  }
`
export const TwitterIcon = styled(Twitter)`
  height: 14px;
  width: 14px;
  fill: #666;
  &:hover {
    fill: #000;
  }
`
export const InstagramIcon = styled(Instagram)`
  height: 14px;
  width: 14px;
  fill: #666;
  &:hover {
    fill: #000;
  }
`
export const PinterestPIcon = styled(PinterestP)`
  height: 14px;
  width: 14px;
  fill: #666;
  &:hover {
    fill: #000;
  }
`
export const YoutubeIcon = styled(Youtube)`
  height: 14px;
  width: 14px;
  fill: #666;
  &:hover {
    fill: #000;
  }
`
export const BackToTopIcon = styled(BackToTop)`
  height: 8px;
  width: 15px;
  margin-right: 10px;
`