import React from 'react'
import SALink from 'components/SALink'
import Preferences from 'components/Preferences'
import Icons from './SocialIcons'
import PaymentOptions from './PaymentOptions'
import ToggleLinks from './ToggleLinks'
import EmailSignup from './EmailSignup'
import { categories, links } from './data'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

import { FooterNav, BackToTopIcon } from './styles'

const Footer = (): React.ReactElement<React.ComponentProps<any>, any> => (
  <FooterNav data-section='footer'>
    <div data-type='line' />
    <button
      type='button'
      data-type='back-to-top-button'
      title='Go to top of the page'
      onClick={() =>
        window &&
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    >
      <div data-type='icon-text-container'>
        <BackToTopIcon />
        <SATypography variant={SATypographyVariant.TINY}>BACK TO TOP</SATypography>
      </div>
    </button>
    <EmailSignup
      style={{
        'min-height': '225px',
      }}
    />

    <section data-section='nav-links'>
      {categories.map(({ section, title }, key) => {
        return <ToggleLinks key={`footer-nav-${key}`} links={links[section]} title={title} />
      })}

      <nav data-type='copyright'>
        <h3>©</h3>
        <p>{new Date().getFullYear()} Saatchi Art</p>
        <p>Leaf Group Commerce</p>
        <p>All Rights Reserved</p>
        <PaymentOptions />
      </nav>
    </section>

    <section data-section='icons'>
      <div data-type='copyright'>
        <p>© {new Date().getFullYear()} Saatchi Art</p>
        <p>Leaf Group Commerce All Rights Reserved</p>
        <PaymentOptions />
      </div>

      <SALink data-type='sa-logo' href='/' aria-label='Saatchi Art Logo'>
        <img
          loading='lazy'
          src='https://d3t95n9c6zzriw.cloudfront.net/footer/saatchiart-final-logo-horizontal-black.svg'
          alt='Saatchi Art'
          width='118'
          height='12'
        />
      </SALink>

      <Preferences />

      <span data-type='spacer' />

      <Icons />
    </section>
  </FooterNav>
)

export default Footer
